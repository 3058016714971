import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icons from '../../Icons';

function BuyButton(props) {
  const { text, className, ...rest } = props;
  return (
    <button className={classnames('buy-button', className)} {...rest}>
      {text}
      <Icons prefix="fas" iconName="shopping-cart" />
    </button>
  );
}

BuyButton.defaultProps = {
  text: '',
  className: ''
};

BuyButton.propTypes = {
  text: PropTypes.string
};

export default BuyButton;
