import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

function LoadMore({ onClick, seenProducts, totalProducts }) {
  const { t } = useTranslation('common');

  return (
    <div className="load-more">
      {seenProducts >= totalProducts ? (
        <h3>
          {t('pagination.all_products_were_loaded')}
          {' '}
          \o/
        </h3>
      ) : (
        <button
          type="button"
          tabIndex={0}
          className="_bg-white _border-0 _text-decoration-underline _text-gray-800 _font-weight-bold _text-2 _text-lg-3 _cursor-pointer"
          onClick={onClick}
          disabled={seenProducts >= totalProducts}
        >
          {t('pagination.show_more')}
          <FontAwesomeIcon icon={faPlus} className="_w-5 _h-5 _ml-2"  />
        </button>
      )}
    </div>
  );
}

LoadMore.defaultProps = {};

LoadMore.propTypes = {
  onClick: PropTypes.func,
  seenProducts: PropTypes.number,
  totalProducts: PropTypes.number
};

export default LoadMore;
