import { useContext } from 'react';
import { MarketplaceContext } from 'components/core/Provider/MarketplaceProvider';
import DisplayImage from 'components/Images/Display';
import { sendAnalyticsEvent } from '../../../utils/datalayer';

function CategorySignup() {
  const marketplaceContext = useContext(MarketplaceContext);
  const { actions, isModalOpen } = marketplaceContext;

  return (
    <div id="category-card-signup" className="category-signup">
      <a
        href="#"
        onClick={e => {
          e.preventDefault();

          sendAnalyticsEvent({
            event: 'interaction',
            event_category: 'Marketplace:CategoryPage',
            event_action: 'Click:SignupCard',
            event_label: 'Register'
          });

          window['optimizely'] = window['optimizely'] || [];
          window['optimizely'].push({
            type: 'event',
            eventName: 'click-product-card-category-page',
            tags: {
              revenue: 0,
              value: 0.0
            }
          });

          actions.setIsModalOpen(!isModalOpen);
        }}
        className="category-signup__link"
      >
        <div className="category-signup__content">
          <div className="category-signup__title">
            Você não precisa só olhar o produto dos outros <span>👀</span>
          </div>

          <div className="category-signup__description--desktop">
            A Hotmart é o primeiro passo para você criar seus próprios produtos
            digitais de forma simples, para começar a vender e lucrar ainda
            hoje.
          </div>

          <div className="category-signup__description--mobile">
            <p>
              A Hotmart é o primeiro passo para você criar seus próprios
              produtos digitais de forma simples, para começar a vender e lucrar
              ainda hoje.
            </p>
          </div>

          <button
            className="category-signup__button"
            onClick={() => actions.setIsModalOpen(!isModalOpen)}
            type="button"
          >
            Clique aqui, você não paga nada pra começar.
          </button>
        </div>

        <div className="category-signup__image">
          <DisplayImage
            className="category-signup__image__img"
            src="/s/static/images/organic/signup-image-card.webp"
            quality={30}
            width={200}
          />
        </div>
      </a>
    </div>
  );
}

export default CategorySignup;
