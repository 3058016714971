import classnames from 'classnames';

const displayImageFallback = 's/static/images/placeholder/display.png';

let errorflag = true;
const onError = e => {
  if (errorflag) {
    errorflag = false;
    e.target.src = displayImageFallback;
  }
};

function DisplayImage({ className, ...props }) {
  return (
    <img
      className={classnames('display-hero-image', className)}
      onError={onError}
      {...props}
    />
  );
}

DisplayImage.defaultProps = {
  src: displayImageFallback
};

export default DisplayImage;
