import React, { useContext } from 'react';

import dynamic from 'next/dynamic';
import Image from 'next/image';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

import { MarketplaceContext } from 'components/core/Provider/MarketplaceProvider';
import { replaceThumborURL } from 'utils/url';
import { substringText, BannerVariants } from 'utils/string';
import { isBestRated } from 'utils/product';
import {
  isCreatorBlackNovember,
  hasCouponBlackNovember
} from 'utils/campaigns';

import DiscountBanner from '../../BlackNovember/DiscountBanner';
import { getLaunchHiddenPrice } from '../../../utils/launchProduct';
import { loaderImage, loaderStaticImage } from '../../../utils/image';
import { localStorageAddProductInformation } from '../../../utils/localStorage';
import Icons from '../../Icons';
import BuyButton from '../../Button/Buy';
import IconsImage from '../../Images/Icons';
import ProductLink from '../../Link/Product';
import ProductImage from '../../Images/Product';

const Price = dynamic(() => import('../../Price'), {
  ssr: false
});

const Tag = dynamic(() => import('components/Tag'), {
  ssr: false
});

const bestRated = loaderStaticImage({
  src: 's/static/images/icons/best_rated.svg'
});

const certificationIcon = loaderStaticImage({
  src: 's/static/images/icons/certification.svg'
});

const clockIcon = loaderStaticImage({
  src: 's/static/images/icons/clock.svg'
});

const tagIcon = loaderStaticImage({
  src: 's/static/images/icons/tag-blue.svg'
});

const videoIcon = loaderStaticImage({
  src: 's/static/images/icons/video.svg'
});

function ProductCardAlt(props) {
  const {
    product = {},
    onClick,
    price,
    discountPrice,
    priceLoading,
    showPrice,
    campaignInfo,
    isMobile,
    searchId,
    origin,
    showBuyButton = false,
    firstImagePriority
  } = props;

  const { t } = useTranslation('common');

  const {
    avatar,
    finalAvatar,
    title,
    ownerName,
    slug,
    producerReferenceCode,
    showRating,
    rating,
    totalReviews,
    coupons,
    locale,
    fullLink,
    description,
    priceDiscount,
    certification,
    totalClasses,
    totalHours,
    productId
  } = product;
  const avatarAvailable = finalAvatar || avatar;
  const staticMediaURL = replaceThumborURL(avatarAvailable);
  const titleSubstring = title ? substringText(title, 60) : '';
  const isProductCard =
    campaignInfo?.PRODUCT_CARD_IMAGE && campaignInfo?.PRODUCT_CARD_LOGO;
  const seasonCampaign = campaignInfo;
  const isLaunchHiddenPrice = getLaunchHiddenPrice(productId);
  const marketplaceContext = useContext(MarketplaceContext);
  const { showNewProductCard, isBlackNovemberActive } = marketplaceContext;
  const productImage = showNewProductCard ? 250 : 184;
  const hasBlackNovemberCoupon = hasCouponBlackNovember(coupons);
  const isCouponActive = isBlackNovemberActive && hasBlackNovemberCoupon;
  const isCreatorOnBlackNovember = isCreatorBlackNovember(isBlackNovemberActive, product?.campaigns);

  const productClick = () => {
    localStorageAddProductInformation(product);
    onClick();
  };

  const renderCertification = () => {
    if (certification && !isMobile && !showNewProductCard) {
      return (
        <div className="product-card-alt__badge product-card-alt__badge-certification">
          <Icons iconName="file-certificate" prefix="fal" />
          {t('general.certificate_of_completion')}
        </div>
      );
    }
    return null;
  };

  const renderClasses = () => {
    if (!isMobile && showNewProductCard) {
      return (
        <div className="product-card-alt__classes">
          {totalClasses && (
            <div className="product-card-alt__total">
              <span>
                <IconsImage src={videoIcon} width={12} height={12} />
              </span>
              {t('general.total_classes', { classes: totalClasses })}
            </div>
          )}
          {totalHours && (
            <div className="product-card-alt__hours">
              <span>
                <IconsImage src={clockIcon} width={12} height={12} />
              </span>
              {t('general.total_hours', { hours: totalHours })}
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  const renderStatus = () => {
    if (!isMobile && showNewProductCard) {
      return (
        <>
          {isBestRated(showRating, rating) && (
            <Tag
              type="blue"
              icon={bestRated}
              label={t('general.best_rated')}
              size="custom-md"
              classes="tag__best-rated"
            />
          )}
          {certification && (
            <Tag
              type="blue"
              icon={certificationIcon}
              label={t('general.certificate_of_completion')}
              size="custom-md"
            />
          )}
        </>
      );
    }

    return null;
  };

  const renderReview = () => {
    return (
      <div className="product-card-alt__status">
        {showRating && rating && (
          <div className="product-card-alt__rating">
            <span>{rating.toFixed(1)}</span>
            <Icons
              className="product-card-alt__rating__star"
              iconName="star"
              prefix="fas"
            />
            {totalReviews && (
              <span className="product-card-alt__rating__total">
                (
                {totalReviews}
                )
              </span>
            )}
          </div>
        )}
        {renderClasses()}
        {renderStatus()}
      </div>
    );
  };

  const renderTitle = () => {
    if (titleSubstring) {
      return (
        <div
          className={classNames(
            'product-card-alt__title ga-product-card-title',
            { 'product-card-alt__title--new': showNewProductCard }
          )}
        >
          {titleSubstring}
        </div>
      );
    }
    return null;
  };

  const renderAuthor = () => {
    if (ownerName) {
      return <div className="product-card-alt__author">{ownerName}</div>;
    }
    return null;
  };

  const renderDescription = () => {
    if (description && !isMobile) {
      return <div className="product-card-alt__description">{description}</div>;
    }
    return null;
  };

  const renderAltPrice = () => {
    if (!isLaunchHiddenPrice && showPrice && !showNewProductCard) {
      return (
        <div className="product-card-alt__info">
          {isLaunchHiddenPrice && (
            <div className="product-card-alt__learn-more">
              {t('general.learn_more')}
              <Image
                src={tagIcon}
                loader={loaderImage}
                priority={false}
                quality={10}
                loading="lazy"
                width={16}
                height={16}
              />
            </div>
          )}
          {isCouponActive && (
            <DiscountBanner
              discountValue={hasBlackNovemberCoupon.discount}
              bannerVariant={BannerVariants.TAG}
            />
          )}
          {isCreatorOnBlackNovember && !hasBlackNovemberCoupon && (
            <DiscountBanner
              creator={t('season.filters.creator_with_discount')}
              bannerVariant={BannerVariants.TAG}
            />
          )}
          {isCouponActive && priceDiscount && (
            <div className="product-card__price--discount">
              <Price isLoading={priceLoading} price={price} />
            </div>
          )}
          <div
            className={classNames('product-card-alt__price', {
              'product-card-alt__price--discount':
                discountPrice && discountPrice !== price
            })}
          >
            <Price isLoading={priceLoading} price={priceDiscount || price} />
          </div>
        </div>
      );
    }
    return null;
  };

  const renderPrice = () => {
    return (
      <div className="product-card-alt__price">
        {showPrice && <Price isLoading={priceLoading} price={price} />}
        {showBuyButton && (
          <div className="product-card__buy-button">
            <BuyButton text={t('search.buy_button')} onClick={() => {}} />
          </div>
        )}
      </div>
    );
  };

  const renderContent = () => {
    if (showNewProductCard) {
      return (
        <>
          {!isMobile && renderReview()}
          {renderAuthor()}
          {renderTitle()}
          {renderDescription()}
          {isMobile && renderReview()}
          {renderPrice()}
        </>
      );
    }

    return (
      <>
        {renderTitle()}
        {renderAuthor()}
        {renderReview()}
        {renderDescription()}
      </>
    );
  };

  return (
    <div
      className={classNames('product-card-alt', {
        'product-card-alt--new': showNewProductCard,
        'product-card-alt--black-november':
          hasBlackNovemberCoupon && isBlackNovemberActive,
        'product-card-alt--creator product-card-alt--black-november':
          isCreatorOnBlackNovember && !hasBlackNovemberCoupon
      })}
    >
      <ProductLink
        slug={slug}
        language={locale}
        seasonSlug={seasonCampaign?.SLUG}
        producerReferenceCode={producerReferenceCode}
        onClick={() => productClick()}
        isSeasonProduct={seasonCampaign?.PREFIX}
        fullLink={fullLink}
        linkTitle={`${titleSubstring} - ${ownerName}: ${price}`}
        searchId={searchId}
        origin={origin}
      >
        <ProductImage
          src={staticMediaURL}
          isSeasonActive={isProductCard}
          className="product-card-alt__image"
          type="product-alt"
          width={productImage}
          height={productImage}
          blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNcu2xtPQAGiQKBAmPWZAAAAABJRU5ErkJggg=="
          placeholder="blur"
          firstImagePriority={firstImagePriority}
        />
        <div className="product-card-alt__content">
          <div className="product-card-alt__content-top">
            <div>{renderContent()}</div>
            {renderCertification()}
            {isMobile && renderAltPrice()}
          </div>
          {!isMobile && renderAltPrice()}
        </div>
      </ProductLink>
    </div>
  );
}

ProductCardAlt.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired,
  featured: PropTypes.bool,
  showBuyButton: PropTypes.bool,
  onBuyButtonClick: PropTypes.func,
  onClick: PropTypes.func,
  idPrefix: PropTypes.string,
  price: PropTypes.string,
  priceLoading: PropTypes.bool,
  showPrice: PropTypes.bool,
  searchId: PropTypes.string,
  firstImagePriority: PropTypes.bool
};

ProductCardAlt.defaultProps = {
  className: '',
  idPrefix: '',
  featured: false,
  showBuyButton: false,
  onBuyButtonClick: () => {},
  onClick: () => {},
  price: '',
  priceLoading: true,
  showPrice: false,
  searchId: '',
  firstImagePriority: false
};

export default React.memo(ProductCardAlt);
