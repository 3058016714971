import { launchProduct } from 'src/constants';

export const getLaunchHiddenPrice = (productId : keyof typeof launchProduct) => {
  const product = launchProduct?.[productId];

  if (!product) return false;

  if (product.PPL_START_DATE && product.PL_START_DATE && new Date(product.PPL_START_DATE) <= new Date() && new Date(product.PL_START_DATE) >= new Date()) {
    return true;
  } if (product.PL_START_DATE && product.L_START_DATE && new Date(product.PL_START_DATE) <= new Date() && new Date(product.L_START_DATE) >= new Date()) {
    return true;
  } if (product.L_START_DATE && product.L_END_DATE && new Date(product.L_START_DATE) <= new Date() && new Date(product.L_END_DATE) >= new Date()) {
    return false;
  }

  return true;
};

